<template>
  <div class="container-fluid mt-3">

    <div class="d-flex flex-wrap mb-1 align-items-center justify-content-between">
      <Breadcrumb current_page="الوحدات الإعلانية"/>

      <router-link class="ms-2 el-button el-button--success el-button--default btn-add"  to="/add-ads">
        إضافة إعلان جديد
      </router-link>
    </div>

    <el-card class="mt-2">
      <div v-if="!loading">
        <el-table id="top-table" :data="adsList" class="mb-4" stripe style="width: 100%"
                  max-height="850" v-loading="loading">

          <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
          <el-table-column prop="name" class-name="px-3" label="اسم الاعلان" width="300"/>

          <el-table-column label="اسم المكان الاعلاني" width="200px">
            <template #default="scope">
              {{scope.row.place?.name}}
            </template>
          </el-table-column>

          <el-table-column label="طول المكان الاعلاني" width="160px">
            <template #default="scope">
              {{scope.row.place?.height}}
            </template>
          </el-table-column>

          <el-table-column  label="عرض المكان الاعلاني" width="160px">
            <template #default="scope">
              {{scope.row.place?.width}}
            </template>
          </el-table-column>

          <el-table-column label="الأدوات" width="220" class-name="control-btns">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="تعديل الإعلان" placement="top">
                <router-link :to="`/edit-ads/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                  <i class="far fa-edit"/>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Loader v-else/>
    </el-card>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";
import {getAds} from "@/services/ads";

export default {
  name: "Ads",
  components: {
    Loader,
    Breadcrumb
  },
  data() {
    return {
      loading: true,
      adsList: []
    }
  },
  mounted() {
    this.getAllAds();
  },
  methods: {
    getAllAds() {
      this.loading = true
      getAds()
      .then(res=>{
        this.loading = false;
        this.adsList = res.data
      })
      .catch(()=>this.loading = false)
    }
  }

}
</script>
